import React from "react";
import { useFetch } from "../../hooks/useFetch";
import { CircularProgress } from "@mui/material";
import { Login } from "./Login";



export const SecurityDriver = ({children}) => {
    const [result,setResult,statusCode] = useFetch("/user/validate/");


    if (statusCode === null ) { return null }
    else if (statusCode === 200) { return children } 
    else { return <Login/> }
}