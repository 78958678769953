import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useFetch } from "../../hooks/useFetch";


export const Login = () => {
    const [email,setEmail] = useState("");
    const [pw,setPw] = useState("");
    const [msg,setMsg] = useState("");
    const [appArray, setAppArray, statusCodeAppArray] = useFetch("/extension/app");


    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("LEL")
        async function tryLogin() {
            if (email !== "" && pw !== "") {
                console.log("LEL")
                await fetch("/api/user/login/",{
                    method: "POST",
                    headers :  { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ "email" : email, "password" : pw } )
                })
                .then(res => {
                    if (res.status === 200) {
                        console.log("success");
                        return res.json();
                    } else 
                    {
                        throw Error(String(res.status))
                    }
                })
                .then(res => {
                        localStorage.setItem('token', res["token"]);
                        localStorage.setItem('is_superuser', res["user"]["is_superuser"]);
                        //localStorage.setItem('idUser', res["idUser"]);
                        //localStorage.setItem('role', res["Role"]);
                        //localStorage.setItem('groups', res["groups"]);
                        window.location.reload(); 
                })
                .catch( error => {
                    if (String(error).includes("404")) {
                        setMsg("Die Anmeldedaten scheinen nicht korrekt zu sein")
                    } else if (String(error).includes("403")) {
                        setMsg(`Ihr Konto wurde deaktiviert!`);
                    } else {
                        setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
                    }
                    setEmail("");
                    setPw("");
                })
            } else {
                setEmail("");
                setPw("");
            }
        }
        tryLogin();
    }


    return(
        <>
            <form onSubmit={handleSubmit} autoComplete="on">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <img width={250} src="/logo_v2.png" />
                    <Box sx={{ mt: 10 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <TextField
                            label="E-Mail"
                            value={email}
                            fullWidth
                            onChange={(event) => setEmail(String(event.target.value))}
                            required
                        />
                    </Grid>

                    <Box sx={{ mt: 2 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <TextField
                            type={'password'}
                            label="Passwort"
                            fullWidth
                            value={pw} onChange={(event ) => setPw(String(event.target.value))}
                            required
                        />

                    </Grid>

                    <Box sx={{ mt: 3 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        <div style={{ float: 'right' }}>
                            <Button size="small" variant="contained" color="primary" type="submit">Anmelden</Button>
                        </div>

                    </Grid>

                    <Box sx={{ mt: 3 }} />
                    <Grid item xs={3} style={{ minWidth: 300 }}>
                        {(msg !== "") &&
                            <Alert severity="error">
                                {msg}
                            </Alert>
                        }
                    </Grid>
                </Grid>
            </form>
        </>
    )
}