import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>SG Neubrandenburg</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
              Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>SG Neubrandenburg</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>10.09.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>S 7 BA 7/23</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>BeckRS 2024, 25121</Typography>
              </Box>

            </Box>

          </Box>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Leitsatz | SG Neubrandenburg - S 7 BA 7/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Ein Gesellschafter-Geschäftsführer einer aus zwei Personen bestehenden GmbH, der 50 v.H. der Anteile am Stammkapital hält, ist nur dann selbständig, wenn ihm gegenüber dem anderen Gesellschafter bei Stimmengleichheit (Pattsituation) ein im Gesellschaftsvertrag verankertes Stichentscheidsrecht zusteht.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | SG Neubrandenburg - S 7 BA 7/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Der Kläger ist alleiniger Geschäftsführer einer GmbH, an der er als Gesellschafter mit exakt 50% beteiligt ist. Beschlüsse werden nach der Satzung mit Stimmenmehrheit (>50%) gefasst. Zwischen GmbH und Geschäftsführer besteht ein Dienstvertrag mit arbeitsvertraglichem Charakter. Gesellschaft und Geschäftsführer gingen wegen der Stellung als Alleingeschäftsführer und Nichtminderheitsgesellschafter von einer selbstständigen Tätigkeit aus. Der Sozialversicherungsträger wertete die Beschäftigung des Geschäftsführers dagegen als abhängige Beschäftigung im Sinne des § 7 SGB IV mit der Folge, dass er in vollem Umfang der Sozialversicherungspflicht unterliegt.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | SG Neubrandenburg - S 7 BA 7/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Das SG Neubrandenburg folgte der Ansicht des Sozialversicherungsträgers und wertete die Tätigkeit des Geschäftsführers als nichtselbstständige Arbeit im Sinne des § 7 SGB IV, sah ihn also nicht als selbstständig an. Eine selbstständige Tätigkeit liege bei einem angestellten Geschäftsführer, der zugleich Gesellschafter ist, nur dann vor, wenn dieser in der GmbH faktisch allein herrschen könne und es ihm (wie einem Selbstständigen) erlaubt sei, die Geschicke der Gesellschaft eigenständig und ohne Rücksicht auf die Mitgesellschafter zu bestimmen. Da der Geschäftsführer gegenüber der Gesellschafterversammlung weisungsgebunden ist (§ 37 Abs. 1 GmbHG), müsse ein Gesellschafter-Geschäftsführer vor allem in der Lage sein, über seine Stimmkraft in der Gesellschafterversammlung ihm nicht genehme Weisungen zu verhindern. Eine solche Blockademöglichkeit sei bei einer Beteiligung von exakt 50% zwar möglich, reiche aber nicht aus. Vielmehr sei erforderlich, dass der Gesellschafter-Geschäftsführer eine aktive Gestaltungsmöglichkeit besitze und in der Lage sei, positive Beschlüsse in der Gesellschafterversammlung herbeizuführen, die sein Handeln als Geschäftsführer stützen. Dazu müsse er entweder Mehrheitsgesellschafter sein (>50%) oder in der Lage sein, bei einer Pattsituation einen positiven Entscheid gegen den Willen der Mitgesellschafter durch Stichentscheid herbeizuführen.Ein Gesellschafter-Geschäftsführer einer aus zwei Personen bestehenden GmbH, der 50 v.H. der Anteile am Stammkapital hält, ist nur dann selbständig, wenn ihm gegenüber dem anderen Gesellschafter bei Stimmengleichheit (Pattsituation) ein im Gesellschaftsvertrag verankertes Stichentscheidsrecht zusteht.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxishinweis | SG Neubrandenburg - S 7 BA 7/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Die Entscheidung steht aus unserer Sicht nicht im Einklang mit der Rechtsprechung des Bundessozialgerichts, die eine selbstständige Tätigkeit bereits dann anerkennt, wenn der Geschäftsführer als Gesellschafter in der Gesellschafterversammlung ein Vetorecht oder eine Sperrminorität besitzt (vgl. nur BSG v. 11.11.2015 ‒ B 12 KR 10/14 R, GmbHR 2016, 533). Eine 50%-Beteiligung stellt eine solche Sperrminorität dar, die (ausnahmsweise) nur dann die Annahme einer abhängigen Beschäftigung des in der GmbH arbeitenden Gesellschafters rechtfertigt, wenn </Typography>

          <ul>
            <li>
            <Typography variant='body1' className='sectionText'>
            dieser als normaler Arbeitnehmer und gerade nicht als Geschäftsführer angestellt ist (ausdrücklich BSG v. 14.03.2018 - B 12 KR 13/17 R, GmbHR 2018, 903 Tz. 21) oder </Typography>
            </li>
            <li>
            <Typography variant='body1' className='sectionText'>
            dieser zwar Geschäftsführer ist, aber ein anderer Mitgesellschafter die Möglichkeit besitzt, durch Stichentscheid, Vetorecht oder Sperrminorität eine Entscheidung gegen den Willen des </Typography>
            </li>
          </ul>

<Typography variant='body1' className='sectionText'>Gesellschafter-Geschäftsführers herbeizuführen (SG Landshut v. 11.1.2024 – S 1 BA 23/23, RFamU 2024, 93).
Bei einer 50%-Beteiligung gilt der Gesellschafter-Geschäftsführer damit sozialversicherungsrechtlich grundsätzlich als selbstständig, da er über eine Blockademacht verfügt. Eine aktive Gestaltungsmöglichkeit in der Gesellschafterversammlung ist hierfür nicht zusätzlich erforderlich.
Die Grundsätze des Bundessozialgerichtes werden in der Praxis bei den Sozialversicherungsträgern immer wieder fehlgedeutet. GmbH-Gesellschafter-Geschäftsführer, die entweder als abhängig beschäftigt (etwa um aus der privaten in die gesetzliche Krankenversicherung zu wechseln) oder umgekehrt als selbstständig gelten wollen (insb. um Arbeitslosen-, Kranken- und Pflegeversicherung zu vermeiden), sollten diese Entscheidung daher zum Anlass nehmen, ihre Satzung entsprechend zu überprüfen, um langwierige Diskussionen mit dem Sozialversicherungsträger und den notwendigen Gang vor Gericht, ggf. auch in die höheren Instanzen zu vermeiden. Ein Stichentscheidsrecht zugunsten des Gesellschafter-Geschäftsführers in der Satzung hätte auch das SG Neubrandenburg zu einer anderen Entscheidung bewogen. Von einer Gründung mit Musterprotokoll sollte in diesen Konstellationen unbedingt Abstand genommen werden.
</Typography>


        </Container>

      </main >
    </div >
  );
}

export default Imprint;