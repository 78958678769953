import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>FG Niedersachsen</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
            Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>Niedersächsisches Finanzgericht</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>29.05.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>3 K 36/24</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>DStR 2024, 1866</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Rechtskraft:</Typography>
                <Typography variant='body1' className='sectionText'>Revision beim BFH eingelegt (IX R 17/24)</Typography>
              </Box>

            </Box>

          </Box>


          

          <Typography variant='h2' className='sectionTitle3 marginTop'>Leitsatz | Niedersächsisches Finanzgericht - 3 K 36/24</Typography>
          <Typography variant='body1' className='sectionText'>
          Teilentgeltliche Übertragungen (Schenkungen unter einer Auflage bzw. mit Gegenleistungen) von Immobilien im Wege der vorweggenommenen Erbfolge unterhalb der historischen Anschaffungskosten sind keine tatbestandlichen Veräußerungen im Sinne des § 23 EStG.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | Niedersächsisches Finanzgericht - 3 K 36/24</Typography>
          <Typography variant='body1' className='sectionText'>
          Der Kläger kaufte im Jahr 2014 ein bebautes Grundstück für insgesamt 143.950 €. Der Kaufpreis wurde teilweise durch Bankdarlehen finanziert. Im Jahr 2019 übertrug er die Immobilie im Wege der vorweggenommenen Erbfolge auf seine Tochter. Im Sinne einer Gegenleistung wurde das in Höhe von 115.000 EUR noch offene Darlehen übernommen. Zum Übertragungszeitpunkt wurde der Verkehrswert mit 210.000 € angegeben.</Typography>
          <Typography variant='body1' className='sectionText'>
          Das Finanzamt bewertete den Vorgang als teilweise entgeltliches Veräußerungsgeschäft im Sinne des § 23 EStG. Die Übertragung sei in einen entgeltlichen und einen unentgeltlichen Vorgang aufzuteilen. Maßstab für die Aufteilung sei dabei der Verkehrswert der Immobilie im Zeitpunkt der Übertragung im Verhältnis zu den übernommenen Verbindlichkeiten. Im Einzelnen:</Typography>

          <table><tbody><tr><td>Verkehrswert 2019</td><td>210.000 €</td><td>100,00%</td></tr><tr><td >entgeltlicher Teil</td><td>115.000 €</td><td>54,76%</td></tr><tr><td>unentgeltlicher Teil</td><td>95.000 €</td><td>45,34%</td></tr></tbody></table>

          <Typography variant='body1' className='sectionText'>
          Die ursprünglichen Anschaffungskosten in Höhe von 143.950 € waren nach Ansicht des Finanzamtes für die Aufteilung nicht relevant. Abziehbar seien dann nur die anteiligen Anschaffungskosten auf den entgeltlichen Teil. Insgesamt habe der Kläger durch die teilweise schenkweise Übertragung an die Tochter einen Veräußerungsgewinn von 40.653 € erzielt. Dieser berechne sich wie folgt:</Typography>
      

          <table ><tbody><tr><td >Veräußerungserlös (Summe der Gegenleistungen)</td><td >115.000 €</td></tr><tr><td >./. Anschaffungskosten (143.950 € x 54,76%)</td><td >78.828 €</td></tr><tr><td >zzgl. AfA 2014 – 2019 (12.185 € x 54,76%)</td><td >6.672 €</td></tr><tr><td >./. Vorfälligkeitsentschädigung (4.000 € x 54,76%)</td><td >2.191 €</td></tr><tr><td >Steuerbarer Veräußerungsgewinn</td><td >40.653 €</td></tr></tbody></table>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Einordnung und Fragestellung | Niedersächsisches Finanzgericht - 3 K 36/24</Typography>
          <Typography variant='body1' className='sectionText'>
          Die Veräußerung von Grundbesitz kann einkommensteuerliche Folgen haben, und zwar auch unterhalb der Schwelle zum gewerblichen Grundstückshandel. § 23 EStG besteuert einen Veräußerungsgewinn, wenn der Zeitraum zwischen Anschaffung und Veräußerung der Immobilie weniger als zehn Jahre beträgt (sog. <b>Spekulationsteuer</b>). Die Frist beginnt dabei nicht zwingend mit dem Datum der Erwerbsurkunde, sondern mit Wirksamwerden des Erwerbsvorgangs. Hing der Erwerb etwa vom Vorliegen einer öffentlichen Genehmigung (bspw. GVO- oder sanierungsrechtliche Genehmigung) oder privaten Zustimmungserklärung (bspw. Zustimmung eines WEG-Verwalters oder bei vollmachtloser Vertretung) ab, so beginnt die Frist erst ab dann. Ein sicheres Indiz für den Fristbeginn ist regelmäßig der Zeitpunkt der Fälligkeit des ursprünglichen Erwerbs.</Typography>
          <Typography variant='body1' className='sectionText'>
          Von der Besteuerung ausgenommen ist eine Veräußerung vor allem dann, wenn der Veräußerer das Objekt im Jahr der Veräußerung und den beiden vorangegangenen Jahren selbst (d.h. in Person des Veräußerers und nicht bloß durch einen Verwandten des Veräußerers) bewohnt hat, § 23 Abs. 1 S. 1 Nr. 1 S. 3 EStG. <b>Wichtig</b>: Es wird keine <b>Selbstnutzung</b> über drei Jahre verlangt, sondern nur eine Nutzung <b>in den betreffenden Kalenderjahren</b>. Wird beispielsweise das Objekt am 2.1.24 veräußert, genügt es für die Steuerfreiheit, wenn der Erwerber am 31.12.22 in das Objekt eingezogen ist. In diesem Fall liegt eine Selbstnutzung in den Jahren 2024, 2023 und 2022 vor.</Typography>
          <Typography variant='body1' className='sectionText'>
          Eine <b>weitere wichtige Ausnahme</b> enthält § 23 Abs. 1 S. 3 EStG. Danach ist die <b>Schenkung</b> von (nicht selbstgenutztem) Grundbesitz innerhalb der Zehnjahresfrist unschädlich und löst keine Spekulationsteuer aus. Das ist konsequent, da kein besteuerungswürdiger Veräußerungsgewinn vorliegt. Die bisher beim Schenker verstrichene Frist läuft dafür aber beim Beschenkten weiter. Sie beginnt beim Beschenkten nicht neu zu laufen. Der Beschenkte hat bei einem anschließenden Verkauf den – mit Blick auf den vom Schenker ursprünglich gezahlten Preis entstandenen – Mehrerlös entsprechend zu versteuern, wenn der Schenker die Immobilie seinerseits vor weniger als zehn Jahren erworben hat. Mit dieser Vorschrift will der Gesetzgeber Umgehungsversuche verhindern. Es soll gerade nicht möglich sein, die Spekulationsteuer dadurch zu umgehen, dass das Objekt innerhalb der Zehnjahresfrist zunächst innerhalb der Familie verschenkt wird, um es anschließend steuerfrei zu veräußern.</Typography>

          <Typography variant='body1' className='sectionText'>
          Das Gesetz regelt aber nicht die Frage, was bei einer Teilschenkung bzw. bei einer Schenkung mit Gegenleistung gilt. In der Praxis werden auch in Schenkungsurkunden regelmäßig Gegenleistungen vereinbart, insbesondere</Typography>

          
          <ul>
            <li>
            <Typography variant='body1' className='sectionText'>
            Ausgleichszahlungen an weichende Erben/Geschwister und</Typography>
            </li>
            <li>
            <Typography variant='body1' className='sectionText'>
            bernahme von Krediten des Veräußerers.</Typography>
            </li>
          </ul>



          <Typography variant='body1' className='sectionText'>
          Die Abschwächung des Schenkungscharakters einer Immobilienübertragung kann ein Instrument sein, um etwa innerhalb der schenkungsteuerlichen Freibeträge zu bleiben oder die Folgen eines Sozialhilferegresses nach § 93 SGB XII i.V.m. § 528 BGB bei einer Heimunterbringung des Schenkers abzumildern.</Typography>
          <Typography variant='body1' className='sectionText'>
          Das <b>Bundesfinanzministerium</b> (BStBl I 1993, 80) hat in einem Schreiben zur „Ertragsteuerlichen Behandlung der vorweggenommenen Erbfolge“ aus dem Jahr 1993 (angepasst im Jahr 2007, BStBl I 2007, 269) in Randziffer 14 verfügt, dass dieser Vorgang in einen entgeltlichen und einen unentgeltlichen Teil aufzuteilen ist. Seither ist die Finanzverwaltung gehalten, diesen entsprechend einer Besteuerung zu unterwerfen.</Typography>
          <Typography variant='body1' className='sectionText'>
          Eine weitere Stütze hat diese Auffassung in einer aktuellen <b>Entscheidung des Bundesfinanzhofs</b> vom 12.12.2023 (IX R 15/23, GmbHR 2024, 992) gefunden. Gegenstand der Entscheidung war eine GmbH-Beteiligung, die im Wege einer gemischten Schenkung teilentgeltlich übertragen wurde. Der BFH hielt es für zulässig, die Übertragung nach dem Verhältnis der tatsächlichen Gegenleistung zum Verkehrswert der übertragenen Anteile in eine entgeltliche Anteilsübertragung und eine unentgeltliche Anteilsübertragung aufzuteilen. Darin liege keine Besteuerung eines fiktiven Sachverhalts, sondern lediglich ein Hilfsmittel zur Beschreibung der Rechtsfolgen, die das Gesetz an den tatsächlich verwirklichten Sachverhalt knüpft (Tz. 24). Nach Ansicht des BFH sei (ohne dies näher zu erläutern) eine solche Aufteilung auch im Anwendungsbereich des § 23 EStG anzuwenden.</Typography>
          <Typography variant='body1' className='sectionText'>
          Nur am Rande sei erwähnt, dass eine solche Aufteilung auf Seiten des Erwerbers für AfA-Zwecke anerkannt und unbestritten ist (BFH vom 5. Juli 1990 GrS 4-6/89, BFHE 161, 317, BStBl II 1990, 847).</Typography>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | Niedersächsisches Finanzgericht - 3 K 36/24</Typography>
          <Typography variant='body1' className='sectionText'>
          Das FG erkennt zwar im Grundsatz an, dass eine Aufteilung in einen entgeltlichen und einen unentgeltlichen Teil vorzunehmen sei. Es stört sich in vorliegendem Fall aber daran, dass (statt eines fiktiven Sachverhalts) ein (nur) fiktiver Ertrag besteuert werde, ohne dass ein positiver Vermögenstransfer zugunsten des Veräußerers/Schenkers erfolgt ist. Der Schenker erhalte im Saldo gerade keinen besteuerungswürdigen Vermögenszufluss, aus dem eine Steuerlast gezahlt werden könne.</Typography>
          <Typography variant='body1' className='sectionText'>
          Deshalb sei im Wege einer <b>teleologischen Auslegung</b> die teilentgeltliche Übertragung aus dem Tatbestand des § 23 EStG jedenfalls dann auszuscheiden, wenn die zu zahlende Gegenleistung (hier 115.000 EUR) unterhalb der historischen Anschaffungskosten (hier 143.950 EUR) liegt. Steuergegenstand der Regelung in § 23 Abs. 1 Nr. 1 EStG sind nur echte, d.h. realisierte Werterhöhungen oder Wertminderungen aus verhältnismäßig kurzfristigen Umsatzgeschäften von Immobilien im Privatvermögen. Der Schenker habe damit eine zusätzliche Steuerlast zu tragen, ohne im Rahmen der Übertragung zusätzliche Mittel erhalten zu haben, die eine Besteuerung von Einkünften (hier in Höhe von 40.653 EUR) rechtfertigten.</Typography>
          <Typography variant='body1' className='sectionText'>
          Insoweit unterscheidet sich der Sachverhalt von dem o.g. BFH-Urteil (12.12.2023 - IX R 15/23, GmbHR 2024, 992). Dort hat der Schenker die Anteile für 25.000 EUR erworben und für 30.000 EUR veräußert, also einen Vermögenszufluss von 5.000 EUR erhalten.</Typography>
          <Typography variant='body1' className='sectionText'>
          Das FG sieht zudem eine verbotene Doppelbesteuerung, da der Vorgang ohnehin bereits der Schenkungsteuer unterliege. Eine Aufteilung, die zu einer Besteuerung der Gegenleistung auch unterhalb der Anschaffungskosten führt, wäre dann Gegenstand von zwei Besteuerungsregimen. Es hält außerdem die Besteuerung für einen Verstoß gegen das Gleichbehandlungsgebot (Art. 3 Abs. 1 GG), weshalb § 23 EStG jedenfalls im Wege der verfassungskonformen Auslegung nicht auf solche Sachverhalte (Gegenleistung niedriger als Anschaffungskosten) anzuwenden sei.</Typography>

          
          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxishinweis | Niedersächsisches Finanzgericht - 3 K 36/24</Typography>
          <Typography variant='body1' className='sectionText'>
          Der Fall zeigt sehr deutlich, welche einkommensteuerlichen Fallstricke auch bei Übertragungen innerhalb der Familie lauern können, und zwar aufseiten des Schenkers. Der Notar sollte bei jeder Übertragung einer vermieteten Immobilie das Anschaffungsjahr im Blick behalten und darauf hinweisen, dass eine Besteuerung nach § 23 EStG möglich ist und ggf. steuerlicher Rat eingeholt werden sollte. Da Ausgangspunkt der Verhältnismäßigkeitsberechnung die Wertangabe in der notariellen Urkunde ist, sollte hier eine möglichst valide Wertschätzung (insb. Verkehrswertgutachten) zugrunde gelegt werden. Grobschätzungen können hier teuer werden. Die Beteiligten müssen sich letztlich vor Augen halten, welche Ziele mit der Schenkung verfolgt werden und vor diesem Hintergrund abwägen, ob eine ggf. anfallende Steuerlast die Übertragung dennoch gerechtfertigt ist oder andere Optionen in Betracht kommen.</Typography>
          <Typography variant='body1' className='sectionText'>
          Eine <b>sehr sinnvolles Gestaltungsinstrument</b> können in diesem Fall vom Veräußerer vorbehaltene Nutzungsrechte (etwa ein Nießbrauchrecht bei vermieteter oder ein Wohnungsrecht bei unvermieteter Immobilie) sein. Gemäß Tz. 15 des o.g. BMF-Schreibens ist bei Aufteilung des Rechtsgeschäfts in den entgeltlichen und den unentgeltlichen Teil dem Entgelt der um den Kapitalwert des Nutzungsrechts geminderte Wert des Wirtschaftsguts gegenüberzustellen. Hierzu ein Beispiel: Verschenkt also etwa der Eigentümer (männlich, 68 Jahre alt, durchschnittliche Lebenserwartung: 15,51 Jahre; Vervielfältiger von Nutzungsrechten: 10,539) eine vor neun Jahren für 250.000 EUR erworbene und nunmehr vollvermietete (Jahresnettokaltmiete: 26.881 EUR – beachte die Grenze des § 16 BewG) Immobilie in Hohen Neuendorf mit aktuellem Verkehrswert von 500.000 EUR an seine Tochter unter Schuldübernahme von 115.000 EUR und behält sich einen Nießbrauch vor, beträgt der Wert des Nießbrauchs 283.298 EUR (Jahreswert x Vervielfältiger) und der Wert Immobilie daher 216.701 EUR (Verkehrswert abzgl. Wert des Nießbauchs).</Typography>



          <table><tbody><tr><td>Verkehrswert aktuell</td><td>283.298 €</td><td>100,00 %</td></tr><tr><td>entgeltlicher Teil</td><td>115.000 €</td><td>40,59 %</td></tr><tr><td>unentgeltlicher Teil</td><td>168.298 €</td><td>59,41 %</td></tr></tbody></table>


          <Typography variant='body1' className='sectionText'>
          Ohne Nießbrauch läge der Verkehrswert bei 500.000 EUR, d.h. die Gegenleistung in Höhe von 115.000 EUR führt dazu, dass der Vorgang nur zu 23% entgeltlich ist. Der Gegenleistung in Höhe von 115.000 EUR können also Anschaffungskosten in Höhe von 57.500 EUR (23% von 250.000 EUR) entgegengehalten werden. Es entsteht also (vereinfacht ohne Berücksichtigung der AfA und ggf. Vorfälligkeitsentschädigung) ein Veräußerungsgewinn in Höhe von 57.500 EUR. Bei Nießbrauchvorbehalt sind 40,59% der Anschaffungskosten zu berücksichtigen, d.h. 101.475 EUR. Es entsteht damit ein (vereinfachter) Veräußerungsgewinn von „nur“ 13.525 EUR. Diese Folge ist (folgt man der Auffassung des Bundesfinanzministeriums) auch konsequent. Immerhin „veräußert“ der Schenker die Immobilie nicht, sondern behält sich den Nutzwert zurück. Deshalb muss der Veräußerungsgewinn auch zwingend geringer sein.</Typography>
          <Typography variant='body1' className='sectionText'>
          Überall dort, wo infolge überdurchschnittlicher Bodenrichtwertsteigerungen in den letzten zehn Jahren eine überdurchschnittlich hohe Wertsteigerung der Immobilien entstanden ist (insbesondere in den berlinnahen Gemeinden/Städten wie Oranienburg, Hohen Neuendorf, Velten, Hennigsdorf, Oberkrämer, Birkenwerder, Glienicke/Nordbahn und Mühlenbecker Land), sollte in diesen Konstellationen über Nutzungsvorbehalte unbedingt nachgedacht werden, um das Besteuerungspotential klein zu halten.</Typography>
          <Typography variant='body1' className='sectionText'>
          Es ist in jedem Fall zu begrüßen, dass sich der Bundesfinanzhof in der Revision (derselbe Senat wie in der o.g. Entscheidung aus dem Jahr 2023) nun mit dem Sachverhalt befassen muss und eine zu lange wenig hinterfragte Verwaltungsauffassung einer eingehenden Prüfung unterzogen wird. Das Ergebnis darf mit Spannung erwartet werden.</Typography>
          <Typography variant='body1' className='sectionText'>
          In der Gestaltungspraxis sind die Vorgaben des Bundesfinanzministeriums aber unbedingt weiter zu beachten, jedenfalls solange der BFH in dieser Sache nicht ausdrücklich anders entscheidet und kein Nichtanwendungserlass veröffentlicht wird.</Typography>





        </Container>

      </main >
    </div >
  );
}

export default Imprint;