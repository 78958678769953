import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>FG Mecklenburg-Vorpommern</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
            Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>Finanzgericht Mecklenburg-Vorpommern</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>25.06.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>1 K 180/23</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>BeckRS 2024, 20760</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Rechtskraft:</Typography>
                <Typography variant='body1' className='sectionText'>Revision beim BFH eingelegt (II R 23/24)</Typography>
              </Box>

            </Box>

          </Box>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Leit- und Orientierungssätze | FG Mecklenburg-Vorpommern - 1 K 180/23</Typography>
          <Typography variant='body1' className='sectionText'>
          1. Nicht vom Grundstücksbegriff des GrEStG erfasst und daher von der Grunderwerbsteuer ausgenommen sind sog. Scheinbestandteile eines Grundstücks (§ 95 BGB), also Bestandteile, die nur zu einem vorübergehenden Zweck mit dem Grund und Boden verbunden worden sind. Hierzu können ausnahmsweise auch Bäume und Bepflanzungen des Grundstücks zählen, wenn bereits im Zeitpunkt der Aussaat oder Pflanzung die Absicht bestand, sie nicht dauerhaft auf dem Grundstück zu belassen und sie wieder zu entfernen. </Typography>
          <Typography variant='body1' className='sectionText'>
          2. Scheinbestandteil kann insbesondere der Aufwuchs forstwirtschaftlicher Grundstücke sein. Hierfür trifft den Steuerschuldner aber die objektive Feststellungslast. Einen Erfahrungssatz im Sinne eines Anscheinsbeweises, dass die aktuelle forstwirtschaftliche Nutzung eines Grundstücks darauf schließen lasse, dass dies auch schon bei Anpflanzung dem Willen des damaligen Grundstückseigentümers entsprochen habe, gibt es nicht. </Typography>
          <Typography variant='body1' className='sectionText'>
          3. Gegen das Vorliegen von aufstehenden Bäumen als sonderrechtsfähigen Scheinbestandteilen spricht, wenn die Vertragsschließenden im Kaufvertrag keine Kaufpreisaufteilung vorgenommen haben und an keiner Stelle ein expliziter Verkauf und die Übereignung der Bäume als sonderrechtsfähige Scheinbestandteile geregelt ist.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | FG Mecklenburg-Vorpommern - 1 K 180/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Der Kläger kaufte ein als unbebaut bezeichnetes Grundstück. Der Verkäufer erklärte, es handele sich um fortwirtschaftlich genutzten Grundbesitz, der jedoch unvermietet und unverpachtet, also eigengenutzt sei. Einen Forstbetrieb hat der Verkäufer jedoch nicht geführt. Gegen den Grunderwerbsteuerbescheid legte der Käufer Einspruch ein und begehrte einen Abzug des Aufwuches und damit eine Reduzierung der Bemessungsgrundlage um 60%. Nach seiner Ansicht entfalle vom Kaufpreis lediglich ein Wert in Höhe von 40% auf das Grundstück selbst.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Einordnung und Fragestellung | FG Mecklenburg-Vorpommern - 1 K 180/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Das Grunderwerbsteuergesetz besteuert nur die Veräußerung eines Grundstücks. § 2 Abs. 1 Satz 1 GrEStG verweist hierzu auf den Grundstücksbegriff des BGB. Vom Grundstücksbegriff ist nicht nur das Erdreich, d.h. der Grund und Boden umfasst, sondern auch die wesentlichen Bestandteile des Grundstücks (§§ 93 f. BGB). Sie gehören zum Grundstück dazu und sind selbst (d.h. ohne das Grundstück) nicht sonderrechtsfähig. § 94 Abs. 1 BGB ordnet zu den wesentlichen Bestandteilen alle Sachen, die mit dem Grund und Boden fest verbunden sind, insbesondere Gebäude (sowie alle Gebäudebestandteile nach § 94 Abs. 2 BGB: Türen, Fenster, Dach etc.). Es gibt also (grundsätzlich) kein gesondertes Gebäudeeigentum.</Typography>
          <Typography variant='body1' className='sectionText'>
          Sind Sachen/Dinge nicht wesentliche, sondern unwesentliche Bestandteile bzw. Zubehör, können Sie aus dem Kaufpreis „herausgerechnet“ werden, sodass sie bei der Grunderwerbsteuer keine Berücksichtigung finden. Das kann sich durchaus lohnen. Bei einer Grunderwerbsteuer von 6,5% (etwa in Brandenburg) des Kaufpreises werden 65 EUR pro 1.000 EUR herausgerechneten Kaufpreisanteil an Grunderwerbsteuer „gespart“. Dies gilt insbesondere für Einbaumöbel, Einbauküchen und verbaute Gerätschaften im Haushalt die ohne Weiteres ausgebaut und an anderer Stelle wieder eingebaut werden können. </Typography>
          <Typography variant='body1' className='sectionText'>
          Nicht vom Grundstücksbegriff erfasst sind sog. Scheinbestandteile i.S.d. § 95 BGB, also Gegenstände, die nur zu einem vorübergehenden Zweck mit dem Grund und Boden fest verbunden sind. </Typography>
          <Typography variant='body1' className='sectionText'>
          Fraglich war die Grundstückszugehörigkeit immer wieder für Pflanzen. In § 94 Abs. 1 S. 2 BGB heißt es hierzu: „Samen wird mit dem Aussäen, eine Pflanze wird mit dem Einpflanzen wesentlicher Bestandteil des Grundstücks.“ Im Ausgangspunkt sind aufstehende Gehölze also grundsätzlich wesentliche Bestandteile des Grundstücks, gleich, ob sie durch Selbst- oder Fremdaussaat unmittelbar am Standort gewachsen oder anderweit vorgezogen und eingepflanzt sind (BFH v. 25.01.2022 - II R 36/19; BFH v. 23.02.2022 - II R 45/19). Sie können nach der aktuellen Rechtsprechung des Bundesfinanzhofs aber auch sog. Scheinbestandteile sein, wenn bereits zum Zeitpunkt von Aussaat oder Pflanzung vorgesehen war, sie wieder von dem Grundstück zu entfernen. Das trifft vor allem dann zu, wenn aufstehende Bäume forstwirtschaftlich genutzt, d.h. zur Holzproduktion oder zum planmäßigen Verkauf (etwa als Weihnachtsbäume) gefällt werden sollen. Es spielt auch keine Rolle, wie viel Zeit bis zur planmäßigen Entfernung der Bäume verstreicht (BFH v. 25.01.2022 - II R 36/19; BFH v. 23.02.2022 - II R 45/19).</Typography>
          <Typography variant='body1' className='sectionText'>
          Da in Deutschland relativ wenig ursprünglicher Waldbestand vorhanden ist und Waldbestände überwiegend wirtschaftlich genutzt werden oder jedenfalls einer wirtschaftlichen Nutzung zugänglich sind, wird häufig angenommen, jeglicher Baumbestand sei bei der Grunderwerbsteuer ohne Weiteres bzw. ohne weitere Angaben in der Urkunde „herausrechenbar“. </Typography>




          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | FG Mecklenburg-Vorpommern - 1 K 180/23
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Die Entscheidung des FG Mecklenburg-Vorpommern zeigt, dass es so einfach dann doch nicht ist. Das Gericht folgte der Ansicht der Grunderwerbsteuerstelle und wies die Klage ab, da es ohne weitere Angaben in der Urkunde nicht feststellen konnte, dass es sich bei dem Aufwuchs ausnahmsweise nicht um (steuerbare) Bäume, sondern um Scheinbestandteile handelte. </Typography>
          <Typography variant='body1' className='sectionText'>
          Dazu hätten sich die Beteiligten über den Verkauf sonderrechtsfähiger Bäume – neben dem Grundstück - auch überhaupt erst einmal einig sein müssen, d.h. der Verkäufer dem Käufer die Bäume neben dem Grundstück auch verkaufen müssen. Eine bloßes Herausrechnen von möglichen Scheinbestandteilen im Nachgang der Beurkundung sei jedenfalls nicht möglich. Fehle es an einer gesonderten Einigung über die Bäume, liege auch nur eine Einigung über einen Verkauf von Grundbesitz mit Bäumen (als wesentlichen Bestandteilen) vor. </Typography>
          <Typography variant='body1' className='sectionText'>
          Von einer gesonderten Einigung könne jedenfalls dann ausgegangen werden, wenn eine Kaufpreisaufteilung in Grundbesitz und Aufwuchs vorgenommen werde. Fehle es hieran, spreche dies sogar dafür, dass die Beteiligten beim Abschluss des Kaufvertrages davon ausgegangen waren, es handele sich bei dem Baumbestand um wesentliche Bestandteile des Grundstücks.</Typography>
          <Typography variant='body1' className='sectionText'>
          Schließlich konnte der Käufer auch nicht darlegen, dass die Bäume ursprünglich bei Anpflanzung (im Sachverhalt waren die Bäume ca. 80-120 Jahre alt) zur Fällung und holzwirtschaftlichen Verwertung gedacht waren. Allein der Umstand, dass es sich um einen nicht vollständig natürlichen Wald handele, spreche im Umkehrschluss nicht zwingend für eine holzwirtschaftliche Nutzung. Nach Ansicht des FG sei es auch denkbar, dass die Bäume zum Zweck der Gestaltung der Landschaft, der Schutz des Klimas und des Bodens, die Schaffung von Lebensraum für Wildtiere, die wissenschaftliche Untersuchung bestimmter Pflanzenarten usw. gepflanzt worden waren. Zwar können auch wesentliche Bestandteile zu Scheinbestandteilen umgewidmet werden. Die pauschale und ohne jede nähere Erläuterung im Kaufvertag enthaltene Aussage, der Wald werde auch fortwirtschaftlich genutzt, genüge jedenfalls nicht für eine solche Annahme. </Typography>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxishinweis | FG Mecklenburg-Vorpommern - 1 K 180/23
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Die Entscheidung gibt wichtige Hinweise für die Gestaltungspraxis. Wird ein forstwirtschaftlich genutztes Waldgrundstück veräußert, sollte sich die Urkunde mit folgenden Punkten ausdrücklich befassen:</Typography>

          <ol>
            <li>
            <Typography variant='body1' className='sectionText'>
            <b>Beschreibung des Grundstücks:</b> Die Urkunde sollte es nicht bei der pauschalen Aussage belassen, es handele sich um Wald, sondern die forst- und ggf. holzwirtschaftliche Nutzung herausstellen und ggf. auch Umstände benennen, auch denen sich das ergibt. Der BGH ließ es in der Entscheidung vom 25.01.2022 genügen, wenn die Urkunde entsprechende Umstände erwähnt, die erkennen lassen, dass der Wald für Zwecke des späteren Holzeinschlags angelegt wurde (etwa entsprechend angeordnete Baumzeilen, ggf. auch Angabe der vorhandenen typischerweise zur Holzgewinnung dienenden Arten).</Typography>
            </li>
            <li>
            <Typography variant='body1' className='sectionText'>
            <b>Beschreibung des Vertragsgegenstandes:</b> Werden forstwirtschaftlich genutzte Bäume mitverkauft, sollte dies auch in der Urkunde seinen Niederschlag finden (etwa: „Mitverkauft wird sämtlicher Aufwuchs.“)</Typography>
            </li>
            <li>
            <Typography variant='body1' className='sectionText'>
            <b>Konkretisierung des Kaufpreises: </b> Eine Kaufpreisaufteilung in der Urkunde in Grundstück und Aufwuchs (60% für den Aufwuchs scheint realistisch) ist unvermeidlich. Es war abzusehen, dass ein solches Vorbringen im Rahmen des Einspruchs auf Widerstand in der Finanzverwaltung und in der ersten Instanz führen würde.</Typography>
            </li>
          </ol>

<Typography variant='body1' className='sectionText'>Die Revision beim BFH bleibt abzuwarten; sie wird spannende Antworten liefern.
</Typography>


        </Container>

      </main >
    </div >
  );
}

export default Imprint;