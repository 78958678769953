import * as React from 'react';
import { Box, TextField, Grid, FormControlLabel, Checkbox, Button, Collapse, Alert } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useState } from 'react';
// import { useRef } from 'react';
// import emailjs from '@emailjs/browser';

export default function Contactform() {

    const [checked, setChecked] = React.useState(false);
  
    const handleUncheck = () => {
      setChecked(false); // Setzt den checked-Status auf false, um die Checkbox zu deaktivieren
    };


    const [wasSend, setWasSend] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);
    const [toOftenTry, setToOftenTry ] = useState(false);
    const [currentContact, setCurrentContact] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Message: ""
    });

    const handleChange = (event) => {
        let tmpObject = {...currentContact};
        tmpObject[event.target.name] = event.target.value
        
        setCurrentContact({
            ...tmpObject
        })

        setChecked(event.target.checked);
    }



    async function  handleClickSend() {
        setWasSuccessfully(true);
        setWasSend(false);
        setToOftenTry(false);
        // Settings 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(currentContact)
        };
  
        fetch('/kemto_mail.php', requestOptions)
            .then(res => {
                console.log(res.status);
                if (res.status === 200) { return res.json()}
                else if (res.status === 423) { throw Error("Too many attempts.") }
                else {throw Error(res.Error)}
            })
            .then(res => {
                setCurrentContact({
                    FirstName: "",
                    LastName: "",
                    Email: "",
                    Phone: "",
                    Message: ""
                })
                setWasSend(true);
            })
            .catch(error => {
                console.log(error.message)
                if (error.message === "Too many attempts.") {
                    setToOftenTry(true);
                }
                else {
                    console.error(error);
                    setWasSuccessfully(false);
                }
            })
      }

      async function handleSubmit(e) {
        e.preventDefault();
        handleClickSend();
        e.target.reset();
      }

    // const form = useRef();
    // const sendEmail = (e) => {
    //     e.preventDefault();
    //     document.getElementById("submitBtn").innerHTML = "senden ..."
    //     emailjs
    //         .sendForm('service_ynzzblu', 'template_7scwbng', form.current, {
    //             publicKey: 'TUGAxP2BuuAojqnq3',
    //         })
    //         .then(
    //             () => {
    //                 console.log('SUCCESS!');
    //                 document.getElementById("submitBtn").innerHTML = "Gesendet"
    //                 document.getElementById("formMessageBox").style.display = "block";
    //                 document.getElementById("formSuccess").style.display = "block";
    //                 document.getElementById("formFail").style.display = "none";
    //                 e.target.reset();
    //             },
    //             (error) => {
    //                 console.log('FAILED...', error.text);
    //                 document.getElementById("submitBtn").innerHTML = "Absenden"
    //                 document.getElementById("formMessageBox").style.display = "none";
    //                 document.getElementById("formSuccess").style.display = "none";
    //                 document.getElementById("formFail").style.display = "block";
    //             },
    //         );
    // };

    return (
        <Box className="contactWrapper">
            <Collapse key="error-send" in={!wasSuccessfully}>
                <Alert severity='error'>
                    Ihre Anfrage konnte nicht übermittelt werden. 
                </Alert>
            </Collapse>

            <Collapse key="success-send" in={wasSend}>
                <Alert severity='success'>
                    Ihre Anfrage wurde erfolgreich übermittelt!
                </Alert>
            </Collapse>

            <Collapse key="locked-send" in={toOftenTry}>
                <Alert severity='warning'>
                    Sie haben innerhalb einer Stunde bereits zwei Anfragen gesendet!
                </Alert>
            </Collapse>

            <Box className="contactFormTop">
                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center" wrap="wrap" className='contactFormTopGrid'>
                    <Grid item xs={12} sm={4}>
                        <Box className="contactTopItem">
                            <a href="mailto:info@holland-kreusslein.de">
                                <MailOutlineIcon className='contactTopIcon' />
                                <p className='contactTopText'>info@holland-kreusslein.de</p>
                            </a>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Box className="contactTopItem">
                            <a href="https://goo.gl/maps/rX1UDiJypT1uJF9WA" target="_blank">
                                <LocationOnOutlinedIcon className='contactTopIcon' />
                                <p className='contactTopText'>Prenzlauer Straße 11<br />16515 Oranienburg</p>
                            </a>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Box className="contactTopItem">
                            <a href="tel:+0493301801138">
                                <LocalPhoneOutlinedIcon className='contactTopIcon' />
                                <p className='contactTopText'>03301 - 801138</p>
                            </a>
                        </Box>
                    </Grid>

                </Grid>
            </Box>

            <Box className='contactBottomWrapper'>
                {/* <form action="contact.php"> */}
                {/* <form ref={form} onSubmit={sendEmail}> */}
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" alignContent="center" wrap="wrap" className='contactFormTopGrid'>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text" required id="vorname" name='FirstName' onChange={handleChange} label="Vorname" variant="filled" className='contactInput'/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text" required id="nachname" name='LastName' onChange={handleChange} label="Nachname" variant="filled" className='contactInput' />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" alignContent="center" wrap="wrap" className='contactFormTopGrid'>
                        <Grid item xs={12} sm={6}>
                            <TextField type="email" required id="email" name='Email' onChange={handleChange} label="E-Mail" variant="filled" className='contactInput' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="tel" id="telefon" name='Phone' onChange={handleChange} label="Telefon" variant="filled" className='contactInput' />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" alignContent="center" wrap="wrap" className='contactFormTopGrid'>
                        <Grid item xs={12} sm={12}>
                            <TextField id="nachricht" required name='Message' onChange={handleChange} label="Ihre Nachricht an uns" multiline rows={4} variant="filled" className='contactInput' />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" alignContent="center" wrap="wrap" className='contactFormTopGrid' id="contactFormBottomControls">
                        <Grid item xs={12} sm={8}>
                            <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} id="checker" required sx={{ color: '#9AAFB7', '&.Mui-checked': { color: '#9AAFB7', }, }} />} label="Ich bin mit der Verarbeitung der eingegebenen Daten sowie der Datenschutzerklärung einverstanden.*" id="contactCheckbox" />                           
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button variant='contained' color='primary' className='contactFormBtn' type="submit" id="submitBtn">Absenden</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            <Box>
                <Grid container spacing={2} id="formMessageBox">
                    <Grid item xs={12} sm={12}>
                        <p id="formsuccess">Ihre Daten wurden erfolgeich gesendet. Wir werden uns so schnell wie möglich bei Ihnen melden.</p>
                        <p id="formFail">Es gab einen Fehler beim Senden der Daten. Bitte versuchen Sie es noch einmal oder erreichen Sie uns Telefonisch.</p>
                    </Grid>
                </Grid>

            </Box>

        </Box >

    );
}