import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>OLG Schleswig</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
            Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>Oberlandesgericht Schleswig</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>26.02.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>16 U 93/23</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>NZG 2024, 490</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Rechtskraft:</Typography>
                <Typography variant='body1' className='sectionText'>ja</Typography>
              </Box>

            </Box>

          </Box>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Orientierungssatz | OLG Schleswig - 16 U 93/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Zu den Aufgaben eines GmbH-Geschäftsführers gehört auch, für eine genügende Versicherung des Betriebsvermögens zu sorgen. Er hat dafür Sorge zu tragen, dass die Versicherungssumme bei der Inhalts- und Betriebsunterbrechungsversicherung dem realen Anlagevermögen entspricht.</Typography>
          
          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | OLG Schleswig - 16 U 93/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Im Zuge der Aufnahme des Betriebes im Jahr 2000 einer GmbH wurden eine Inhalts- und Betriebsunterbrechungsversicherung mit einer Versicherungssumme von 143.000,- € abgeschlossen. Im November 2017 ging dem Geschäftsführer der GmbH eine Prämienrechnung der Versicherung zu, aus der sich für die Geschäftsversicherung eine Versicherungssumme von – unveränderten – 143.000,- € ergab. Die Bäckerei wurde bei einem Brand am im August 2018 beschädigt. Der von der Versicherung beauftragte Sachverständige ermittelte in seinem Gutachten bei einem angenommenen Versicherungswert von 370.000,- € eine <b>Unterversicherung</b> im Umfang von 61,5 %, wonach die Versicherung entsprechend seiner Feststellungen eines Sachschadens von 144.060,44 € und eines Betriebsunterbrechungsschadens von 162.015,38 € auf den Gesamtbetrag von 306.075,82 € lediglich <b>38,5%</b>, also 117.839,19 € zahlte.</Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Einordnung und Fragestellung | OLG Schleswig - 16 U 93/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Ein Geschäftsführer hat in den Angelegenheiten der Gesellschaft die Sorgfalt eines ordentlichen Geschäftsmannes anzuwenden (§ 43 Abs. 1 GmbHG). Nach § 43 Abs. 2 GmbH haftet ein Geschäftsführer, der seine Obliegenheiten verletzt, der Gesellschaft für den entstandenen Schaden, und zwar ungeachtet eines Anstellungsvertrages allein aus seiner Organstellung heraus.</Typography>          
          <Typography variant='body1' className='sectionText'>
          Welche Obliegenheiten den Geschäftsführer treffen, sagt das Gesetz an keiner Stelle. Die Rechtsprechung hat eine Vielzahl von Fallgruppen herausgearbeitet. Hierzu gehört insbesondere die Vermögensbetreuungspflicht des Geschäftsführers gegenüber der GmbH bzw. ihrem Gesellschaftsvermögen.</Typography>          

          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | OLG Schleswig - 16 U 93/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Nach Ansicht des OLG Schleswig zählt dazu auch die Verpflichtung, für eine genügende Versicherung des Betriebsvermögens zu sorgen und eine Unterversicherung zu vermeiden.</Typography>          
          <Typography variant='body1' className='sectionText'>
          Ein Geschäftsführer einer GmbH muss deshalb zur Vermeidung einer möglichen Unterversicherung dafür sorgen, dass die Versicherungssumme bei der Inhalts- und Betriebsunterbrechungsversicherung dem realen Anlagevermögen adäquat entspricht. Diese Pflicht besteht laufend, d.h. der Geschäftsführer muss Wertveränderungen (Anstieg des Neuwertes von Anlagevermögen infolge Teuerung/Inflation) und Neuanschaffungen berücksichtigen und ggf. die Versicherungssumme den realen Verhältnissen anpassen und nach oben korrigieren. Von einem Geschäftsführer darf erwartet werden, dass er sich einen vertieften Überblick über die Versicherungsverhältnisse verschafft und eine Unterversicherung erkennt.</Typography>
          <Typography variant='body1' className='sectionText'>
          Seine Verantwortung kann er weder auf einen Versicherungsmakler oder die Versicherung selbst abwälzen. Eine Verpflichtung des Versicherers zur Beratung des Versicherers besteht während der Dauer des Vertragsverhältnisses nur, soweit für den Versicherer ein Anlass für eine Nachfrage und Beratung des Versicherungsnehmers erkennbar ist. Es ist aber grundsätzlich Sache des Versicherungsnehmers, seinen Versicherungsbedarf selbst zu ermitteln und das zu versichernde Risiko abzuschätzen. Anders ist dies nur, wenn der Versicherungsnehmer um Hilfe bei der Feststellung des Versicherungsbedarfs bittet.</Typography>          
          <Typography variant='body1' className='sectionText'>
          Allein der Umstand, dass bei näherem Hinsehen auch die Versicherung auf den Gedanken hätte kommen können, dass die zur Jahrtausendwende bestimmte Versicherungssumme womöglich dem realen Anlagevermögen, über das sie nichts wusste und auch nichts wissen musste, nicht mehr adäquat sein könnte, begründet eine (Mit-)Haftung der Versicherung nicht.</Typography>
          
          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxishinweis | OLG Schleswig - 16 U 93/23</Typography>
          <Typography variant='body1' className='sectionText'>
          Diese Entscheidung zeigt eindrucksvoll, dass sich ein Geschäftsführer in jeder Hinsicht verantwortungsvoll um seine GmbH und ihr Gesellschaftsvermögen zu kümmern hat. Das Gesellschaftsvermögen und das betriebene Unternehmen sind – sofern die Gesellschafterversammlung keinen anderslautenden Beschluss fasst – angemessen zu versichern und versichert zu halten. Das Thema mag unbequem und lästig sein; ein Wegducken und Verschieben ist aber haftungsträchtig. Im Zweifel sollte ein Geschäftsführer proaktiv auf die Versicherung zugehen und unter Übersendung eines Anlagenverzeichnisses bzw. - spiegels eine Neubewertung veranlassen, insbesondere bei Neuanschaffungen in nicht unerheblichem Umfang. Zu beachten ist außerdem, dass die eine Unterversicherung nicht anhand der Anschaffungskosten, sondern anhand des Neuwertes zu ermitteln ist. Die allgemeine Inflation ist im Blick zu behalten. Bei mehr als 10% sollte der Geschäftsführer spätestens aktiv werden.</Typography>


        </Container>

      </main >
    </div >
  );
}

export default Imprint;