import logo from '../logo.svg';
import { Typography, Paper, AppBar, Button, Box, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Link, Toolbar, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ResponsiveAppBar from './ResponsiveAppBar';
import Contactform from './Contactform';
import '../App.css';

const FGSchleswig = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>FG Schleswig</Typography>

          <Box className="articleThumbBox">
            <Typography variant='body1' className='sectionText'>
            Entscheidungsdaten
            </Typography>

            <Box className="articleThumbGrid">

              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Gericht:</Typography>
                <Typography variant='body1' className='sectionText'>Schleswig- Holsteinisches FG</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Datum:</Typography>
                <Typography variant='body1' className='sectionText'>17.09.2024</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Aktenzeichen:</Typography>
                <Typography variant='body1' className='sectionText'>4 K 34/24</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Fundstelle:</Typography>
                <Typography variant='body1' className='sectionText'>juris</Typography>
              </Box>
              <Box className="articleThumbGridItem">
                <Typography variant='body1' className='sectionText'>Rechtskraft:</Typography>
                <Typography variant='body1' className='sectionText'>Revision beim BFH eingelegt (VIII R 30/24)</Typography>
              </Box>

            </Box>

          </Box>


          

          <Typography variant='h2' className='sectionTitle3 marginTop'>Orientierungssätze | Schleswig- Holsteinisches FG - 4 K 34/24</Typography>
          <Typography variant='body1' className='sectionText'>
            1. Wird der Kaufpreis beim Grundstücksverkauf gestundet (Kauf auf Raten), handelt es sich um ein Darlehen des Verkäufers an den Käufer. Das führt grundsätzlich zu Einkünften aus Kapitalvermögen gemäß § 20 Abs. 1 Nr. 7 EStG, und zwar selbst dann, wenn die Vertragsparteien eine Verzinsung ausdrücklich ausgeschlossen haben.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            2. Zur Berechnung des Kapitalertrags sind die Kaufpreisraten gemäß § 12 Abs. 3 BewG in einen Zins- und einen Tilgungsanteil aufzuteilen, wobei von einem Zinssatz von 5,5% auszugehen ist.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            3. Der Zins- bzw. Kaufpreisvorteil des Verkäufers kann verschenkt werden. In diesem Fall ist die Schenkung als freigebige Zuwendung im Sinne des § 7 Abs. 1 ErbStG zu qualifizieren und die Ertragsbesteuerung des Zinsanteils (§ 20 Abs. 1 Nr. 7 EStG ) rechtssystematisch zurück.
          </Typography>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Sachverhalt | Schleswig- Holsteinisches FG - 4 K 34/24</Typography>
          <Typography variant='body1' className='sectionText'>
            Die Grundstückseigentümer A und B verkauften ihrer Tochter eine Immobilie. 
            <br/>Im Kaufvertrag hieß es:
          </Typography>
          <Typography sx={{ml: 2}} className='sectionText'>
            „Die Parteien geben den Wert der Immobilie mit xxx € an. Der Erwerber verpflichtet sich, xxx € an den Veräußerer zu zahlen. Der Betrag wird zunächst gestundet. Der Erwerber zahlt dem Veräußerer diesen Betrag in monatlichen Raten á 900,00 € ab dem xx.xx.2022. Eine Verzinsung ist nicht vereinbart. Die in diesem Verzicht liegende Kaufpreisreduzierung wird dem Erwerber geschenkt. Ergänzend vereinbaren die Parteien, dass die monatliche Rate im gegenseitigen Einvernehmen alle 5 Jahre um bis zu 5% erhöht werden kann, bei entsprechender Verkürzung der Laufzeit. Sollte einer der Veräußerer versterben, ist der Vertrag mit seinen Erben fortzusetzen. Stirbt der Erwerber, sind dessen Erben zur Sondertilgung berechtigt, aber nicht verpflichtet.“
          </Typography>  
          <Typography variant='body1' className='sectionText'>
            Das Finanzamt erfasste sodann einen in den Kaufpreisraten enthaltenen Zinsanteil gemäß § 20 Abs. 1 Nr. 7 EStG jeweils hälftig bei den Einkünften der Eltern aus Kapitalvermögen, wodurch sich deren Einkommensteuer in den Jahren 2021 und 2022 erhöhte.
          </Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Einordnung und Fragestellung | Schleswig- Holsteinisches FG - 4 K 34/24</Typography>
          <Typography variant='body1' className='sectionText'>
            Bei einem Grundstückskauf – insbesondere innerhalb der Familie – kommt den Beteiligten nicht selten der Gedanke, den Kaufpreis nicht bei einer Bank durch Aufnahme eines Kredites zu finanzieren und sofort in voller Höhe an den Veräußerer zu bezahlen, sondern diesen in Raten abzuzahlen. Der Veräußerer erhält dann den Kaufpreis nicht sofort, sondern über einen bestimmten Zeithorizont in Raten ausgezahlt.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            Bei wirtschaftlicher Betrachtung handelt es sich bei dieser Kaufpreisstundung um ein abgekürztes Kreditgeschäft. Der Veräußerer hätte dem Erwerber – neben dem verkauften Objekt – zusätzlich auch selbst einen Kredit in Höhe der Kaufsumme gewähren können, den der Erwerber (wie bei einer Bank) in monatlichen Raten abzahlt. Der Unterschied zur Kauf- und Finanzierungsabwicklung über eine Bank besteht nun darin, dass sich das Kreditgeschäft nicht in einem Drei-Personen-Verhältnis (Erwerber-Bank-Veräußerer), sondern eine Zwei-Personen-Verhältnis bewegt, bei dem der Veräußerer zusätzlich den Part der Bank übernimmt (Erwerber-Veräußerer=Bank), also einen Verkäuferkredit gewährt.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            Finanziert eine Bank den Kaufpreis, tut sie das im Rahmen bzw. Ausübung ihres Gewerbes und verlangt eine Gegenleistung für die Zurverfügungstellung des Geldes, nämlich den Zins. Zinseinnahmen der Bank unterliegen selbstverständlich der Einkommensteuer, sind also zu versteuern. Diesen Gedanken überträgt der BFH in ständiger Rechtsprechung auch auf den Fall der längerfristigen Kaufpreisstundung bzw. die Ratenzahlungsabrede und zerlegt den Gesamtkaufpreis in einen Zins- und Tilgungsanteil. Es wird davon ausgegangen, dass ein wirtschaftlich und rational denkender Erwerber bei sofortiger Zahlung einen niedrigen Preis als den vereinbarten und gestundeten Kaufpreis gezahlt hätte. Bei der Bestimmung des Zinsanteils „hilft“ (sofern kein ausdrücklicher Zins vereinbart wurde) § 12 Abs. 3 BewG weiter. Darin heißt es:
          </Typography>
          <Typography sx={{ml: 2}} className='sectionText'>
            „Der Wert unverzinslicher Forderungen oder Schulden, deren Laufzeit mehr als ein Jahr beträgt und die zu einem bestimmten Zeitpunkt fällig sind, ist der Betrag, der vom Nennwert nach Abzug von Zwischenzinsen unter Berücksichtigung von Zinseszinsen verbleibt. <b>Dabei ist von einem Zinssatz von 5,5 Prozent auszugehen.</b>“
          </Typography>  
          <Typography variant='body1' className='sectionText'>
            Als Berechnungshilfe dient Anlage 9a des BewG. Soll beispielsweise ein Kaufpreis in Höhe von 120.000 EUR über einen Zeitraum von 10 Jahren in monatlichen Raten von 1.000 EUR zurückgezahlt werden, ist der Jahresbetrag (12.000 EUR) mit dem aus der Tabelle (Anlage 9a) zu entnehmenden Vervielfältiger (bei 10 Jahren = 7,745) zu multiplizieren. Der gegenwärtige Kapitalwert (d.h. der eigentliche (!) Kaufpreis) beträgt also 92.940 EUR (12.000 EUR x 7,745). Das Finanzamt unterstellt also, dass der zurückgezahlte Gesamtbetrag in Höhe von 120.000 EUR einen Zinsanteil in Höhe von 27.060 EUR (120.000 EUR ./. 92.940 EUR) beinhaltet, der beim Veräußerer zu folgenden jährlichen Zinseinnahmen führt:
          </Typography>
          <table>

            <tbody>
                <tr>
                    <td>Jahr 1</td>
                    <td>Jahr 2</td>
                    <td>Jahr 3</td>
                    <td>Jahr 4</td>
                    <td>Jahr 5</td>
                    <td>Jahr 6</td>
                    <td>Jahr 7</td>
                    <td>Jahr 8</td>
                    <td>Jahr 9</td>
                    <td>Jahr 10</td>
                </tr>
                <tr><td>5.112,97</td>
                <td>4.569,14</td>
                <td>4.023,78</td>
                <td>3.486,92</td>
                <td>2.958,48</td>
                <td>2.438,43</td>
                <td>1.926,71</td>
                <td>1.423,27</td>
                <td>928,06</td>
                <td>441,04</td></tr>
            </tbody>
          </table>
          <Typography variant='body1' className='sectionText'>
            Diese jährlichen Einnahmen sind § 20 Abs. 7 EStG zu versteuern, wobei der Sparerpauschbetrag in Höhe 1.000 EUR abzuziehen ist (§ 20 Abs. 9 S. 1 EStG).
          </Typography>


          <Typography variant='h2' className='sectionTitle3 marginTop'>Entscheidung | Schleswig- Holsteinisches FG - 4 K 34/24</Typography>
          <Typography variant='body1' className='sectionText'>
            Das Finanzgericht wertete die Einordnung des Finanzamtes als Verkäuferdarlehen und seine Berechnung als grundsätzlich zutreffend, wollte in dem Verzicht der Eltern auf sofortige Zahlung des Kaufpreises dennoch keine Einkünfte aus Kapitalvermögen i.S.d. § 20 Abs. 1 Nr. 7 EStG erkennen. Denn diese seien nach Satz 1 legaldefiniert als „Erträge aus sonstigen Kapitalforderungen jeder Art, wenn die Rückzahlung des Kapitalvermögens oder ein Entgelt für die Überlassung des Kapitalvermögens zur Nutzung zugesagt oder geleistet worden ist, auch wenn die Höhe der Rückzahlung oder des Entgelts von einem ungewissen Ereignis abhängt.“ Nach Satz 2 gilt die Einordnung unabhängig von der Bezeichnung und der zivilrechtlichen Ausgestaltung der Kapitalanlage.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            Der Besteuerungstatbestand sei dabei zwar weit gefasst, dürfe jedoch nicht solche Fälle erfassen, in denen nach dem realisierten Sachverhalt weder ein Ertrag erzielt worden ist noch überhaupt von einer Kapitalanlage die Rede sein kann. Werde ein Verzicht auf eine Verzinsung des Kaufpreises erklärt und dem Erwerber eine „Kaufpreisreduzierung geschenkt“, handele es sich – vor allem mit Blick auf das Angehörigenverhältnis – in wirtschaftlich nachvollziehbarer Weise um eine Schenkung, die weder begrifflich eine von § 20 Abs. 1 Nr. 7 EStG vorausgesetzte Kapitalanlage oder ein Ertrag aus einer Kapitalforderung sei. Aus § 12 Abs. 3 BewG ergebe sich nichts anderes, da es sich hierbei lediglich um eine Bewertungsvorschrift handele, die für sich genommen noch keinen Besteuerungstatbestand begründe. 
          </Typography>
          <Typography variant='body1' className='sectionText'>
            Der Sachverhalt trage vielmehr eindeutig Züge einer freigebige Zuwendung oder Schenkung und sei daher lediglich schenkungssteuerlich relevant. Auch der BFH habe in der unentgeltlichen Überlassung einer Kapitalsumme auf Zeit eine Schenkung i.S. des ErbStG betrachtet (BFH-Urteil v. 31.03.2010- II R 22/09, BFHE 229, 374, BStBl II 2010, 806). Gegenstand der Schenkung bzw. freigebigen Zuwendung ist dann nicht ein konkreter Ertrag, der dem Zuwendenden entgeht, sondern die dem Verzicht auf die eigene Nutzungsmöglichkeit seitens des Zuwendenden korrespondierende Gewährung der Nutzungsmöglichkeit durch den Zuwendungsempfänger (BFH v. 12.07.1979 - II R 26/78, BFHE 128, 266, BStBl II 1979, 631). Dabei ist für die Einordnung als freigebige Zuwendung i.S. des § 7 Abs. 1 ErbStG unerheblich, dass die Überlassung von Kapital als solches zivilrechtlich als unentgeltliches Darlehen zu qualifizieren wäre (vgl. BFH v. 30.03.1994 - II R 105/93, BFH/NV 1995, 70).
          </Typography>
          <Typography variant='body1' className='sectionText'>
            Das FG sah die Voraussetzungen einer freigebigen Zuwendung als erfüllt. Mit der Ratenzahlungsabrede bzw. Stundung haben sich die Eltern der Nutzungsmöglichkeit des Kapitals für die Zeit der Stundung begeben. Eine Gegenleistung sei nicht erkennbar. Bei einem Konkurrenzverhältnis zwischen einem ertragsteuerlichen Einkünftetatbestand und einer freigebigen Zuwendung, tritt die Ertragsbesteuerung rechtssystematisch zurück; Kurzum: der mögliche Anfall von Schenkungsteuer blockt die Ertragsteuer.
          </Typography>

          <Typography variant='h2' className='sectionTitle3 marginTop'>Praxis- und Gestaltungshinweise | Schleswig- Holsteinisches FG - 4 K 34/24</Typography>
          <Typography variant='body1' className='sectionText'>
            <b>Hintergrund:</b> Kaufverträge innerhalb der Familie, bei denen die Immobilie zum Verkehrswert an die nächste Generation veräußert wird, sind nicht selten und werden häufig dazu genutzt, späteren Pflichtteilsthemen von vornherein zu begegnen. Wer eine Immobilie bezahlen muss, erhält sie nicht geschenkt; folglich können Geschwister nicht benachteiligt werden und anlässlich der Übertragung bei Tod der Eltern einen Pflichtteil fordern. Häufig fehlt es dem eigenen Nachwuchs aber an dem nötigen Eigenkapital, um einen Kaufpreis in Höhe des Verkehrswertes des Grundstücks aufzubringen. Sie sind daher grundsätzlich auf externe Finanzierungsquellen, insbesondere Banken angewiesen. Insbesondere im berlinnahen Umland (insbesondere Glienicke/Nordbahn, Hohen Neuendorf, Birkenwerder, Hennigsdorf, Velten, Oranienburg, Mühlenbecker Land, Wandlitz etc.) sind die Grundstückswerte aber derart hoch, dass die Kinder nur ausnahmsweise die Bonität für entsprechend hohe Kredite besitzen. Ratenzahlungs- bzw. Stundungsvereinbarungen bieten daher einen angenehmen Ausweg und ermögliche es, Kapital und Liquidität im Familienkreis zu halten. Sie sind aber bisweilen mit dem Makel behaftet, dass der Schenker hiermit ggf. einen Einkommensteuertatbestand verwirklicht.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            <b>Bewertung:</b> Die Entscheidung des FG ist daher zu begrüßen und bei wirtschaftlicher Betrachtungsweise auch nachvollziehbar. Gegenstand einer Schenkung können nicht nur Sachen oder Grundstücke, sondern auch die Nutzungen daran sein. Das ist auch bei Geld möglich, und zwar im Rahmen einer Geldleihe (zur Verfügungstellung von Geld ohne Zinsforderung, d.h. unentgeltlich). Der BFH ordnet diesen Vorgang als Schenkung ein, wobei die Vermögensminderung darin liegt, dass der Schenker das Geld nicht nutzt – also kurz gesagt: die Opportunitätskosten (Was hätte mit dem weggegebenen Betrag alternativ selbst erwirtschaftet werden können?) – und dem nicht ausgeglichen Wert- bzw. Währungsverlust (Inflation). Wenn Geld verschenkt wird, sich also der Schenker der Nutzungs- und Verwertungsmöglichkeit des Geldes entledigt, kann ihm schlechterdings zugleich unterstellt werden, Einnahmen aus der temporären Weggabe des Geldes zu erzielen, die bei ihm zu einer Vermögensmehrung führen. Einem Veräußerer muss es dann auch gestattet sein, im Rahmen eines Kaufvertrages innerhalb der Familie als „Familienbank“ aufzutreten und dem beschenkten eine Geldleihe zu gewähren. 
          </Typography>
          <Typography variant='body1' className='sectionText'>
            Der BFH wird sich mit der Thematik befassen und entscheiden müssen, ob in Familienkonstellationen andere Maßstäbe gelten und Schenker als „Familienbank“ auftreten können, ohne dass die Finanzverwaltung hier einkommensteuerliche Folgen unterstellt. Bis dahin muss den Beteiligten bewusst sein, dass hier ggf. ein steuerlicher Fallstrick lauert. Der Notar muss auf die Thematik hinweisen und den Beteiligten dazu raten, ggf. steuerlichen Rat einzuholen.
          </Typography>
          <Typography variant='body1' className='sectionText'>
            <b>Alternativgestaltung:</b> Die Problematik lässt sich aber insgesamt vermeiden, wenn der Kaufvertrag und der Kreditvertrag nebst ggf. einzutragender Sicherungsgrundschuld getrennt beurkundet werden. Der Kaufvertrag wird also ohne Ratenzahlungsabrede beurkundet und unterscheidet sich in seiner äußeren Gestalt nicht von einem gewöhnlichen Kaufvertrag mit einem Dritten, macht also die Eigentumsumschreibung von der Zahlung des Kaufpreises abhängig. In einer zweiten Urkunde vereinbaren die Eltern mit dem Kind einen Kreditvertrag über die Ausreichung eines Darlehens in Höhe des Kaufpreises und vereinbaren einen Zins- und Tilgungsplan. Zugleich werden die Ausreichung des Kreditbetrages zusammen mit dem Erhalt des Kaufpreises in einem Akt erklärt und damit die Grundlage für die Eigentumsumschreibung geschaffen. Es sollte eine marktübliche Verzinsung gewählt werden, andernfalls handelt es sich um eine vom Notar gegenüber dem Finanzamt anzuzeigende Schenkung (§ 34 ErbStG; § 8 ErbStDV). Die Eltern sind jedoch grundsätzlich frei darin, später auf die Zahlung des Zinses zu verzichten, den späteren Zinsbetrag also an die Kinder als Kreditnehmer zurückzuschenken, was von den Beteiligten dann selbst angezeigt werden muss (§ 30 ErbStG). Um eine Marktüblichkeit zu gewährleisten, sollte außerdem eine (nicht abtretbare und ggf. auflösend bedingte) Sicherungsgrundschuld für die Eltern eingetragen werden. Die Aufspaltung des Vorgangs in zwei Rechtsgeschäfte und Urkunden stellt keine Umgehung dar, sondern beschreibt letztlich nur das eigentlich Gewollte (Verkauf + Kreditgewährung innerhalb der Familie) und das vom Finanzamt auch Unterstellte. 
          </Typography>



        </Container>

      </main >
    </div >
  );
}

export default FGSchleswig;
