import React, { useEffect, useState } from 'react';
import { Typography, Box, Container, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import '../App.css';


const Blog = () => {

  const [articles, setArticles] = useState([]); // Initialisiert ein leeres Array für die Artikel
  const [loading, setLoading] = useState(true); // Einfache Loading-Logik
  const [error, setError] = useState(null); // Fehlerbehandlung

  // API-Aufruf in useEffect, der beim Laden der Komponente ausgeführt wird
  useEffect(() => {
    // API-Call mit fetch
    fetch('/api/index.php/article')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // JSON-Daten aus der Antwort
      })
      .then((data) => {
        setArticles(data); // Daten im articles Array speichern
        setLoading(false); // Loading beenden
      })
      .catch((error) => {
        setError(error.message); // Fehlerbehandlung
        setLoading(false);
      });
  }, []); // Leeres Array als zweites Argument bedeutet, dass useEffect nur einmal läuft




  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>Blog</Typography>

          <Typography variant='body1' className='sectionText'>
          Interessantes und Neues
          </Typography>

          <div>
      <h1>Article List</h1>
      <ul>
        {articles.map((article) => (
          <li key={article.id}>{article.title}</li> // Darstellung der Artikel
        ))}
      </ul>
    </div>

    
          <Box className="categoryList">
            <Box className="categoryItem">
              <Typography variant='body1' className='sectionText'>Neues</Typography>
            </Box>
            <Box className="categoryItem">
              <Typography variant='body1' className='sectionText'>Notargeschäft</Typography>
            </Box>
            <Box className="categoryItem">
              <Typography variant='body1' className='sectionText'>Urteile</Typography>
            </Box>
            <Box className="categoryItem">
              <Typography variant='body1' className='sectionText'>Veranstaltungen</Typography>
            </Box>
          </Box>

          <Box className="blogList">

            <Box className="blogItemWrapper">
              <Box className="blogItemImageWrapper">
                <img src="siegel.jpg" />
              </Box>
              <Box className="blogItemTextWrapper">
                <Typography variant='h2' className='blogItemTitle'>Lorem ipsum dolor sit amet</Typography>
                <Typography variant='body' className='blogItemText'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Typography>
                <Button className='btnSecondarySmall'>Jetzt lesen</Button>
              </Box>
            </Box>

            <Box className="blogItemWrapper">
              <Box className="blogItemImageWrapper">
                <img src="siegel.jpg" />
              </Box>
              <Box className="blogItemTextWrapper">
                <Typography variant='h2' className='blogItemTitle'>Lorem ipsum dolor sit amet</Typography>
                <Typography variant='body' className='blogItemText'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</Typography>
                <Button className='btnSecondary'>Jetzt lesen</Button>
              </Box>
            </Box>

          </Box>


        
        </Container>

      </main >
    </div >
  );
}

export default Blog;