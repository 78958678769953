import * as React from 'react';
import { Typography, Container, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import '../App.css';


const Imprint = () => {
  return (
    <div className="App">

      <main>

        <Container maxWidth="md" className="container serviceContainer">
          <Typography variant='h1' className='sectionTitle'>Karriere</Typography>

          <Typography variant='body1' className='sectionText'>
          Wir suchen Verstärkung für unser Team! Hier finden Sie Informationen zu den von uns angebotenen offenen Stellen. Wir sind gespannt auf Ihre Bewerbungsunterlagen und freuen uns darauf, Sie kennenzulernen.
          </Typography>

          <Accordion className='AccordionWrapper'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Studentische Mitarbeitende im Notarbüro Holland & Kreußlein Oranienburg
        </AccordionSummary>
        <AccordionDetails className='accordionBody'>
   
        <Typography variant='body1' className='sectionText'>
          Wir, die Notare Holland & Kreußlein, suchen ab sofort studentische Mitarbeitende für unser Büro in Oranienburg.
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Sie unterstützen uns bei den praktischen Arbeitsabläufen nach Beurkundung und erhalten einen Einblick in die Arbeitsweise und die Inhalte der notariellen Tätigkeiten. Neben Kopier- und Scanarbeiten betreuen Sie dabei insbesondere auch die Digitale Notarplattform (<a href="https://www.lextorbyte.de/">DiNo</a>) und empfangen und betreuen ankommende Mandanten. Werden Sie Teil eines modernen und serviceorientierten Notarbüros und lernen Sie dabei auch den wohl schönsten juristischen Beruf kennen.
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Sie arbeiten gerne im Team, sind zuverlässig und kommunikativ, sorgfältig und gut belastbar. Ein gepflegter Umgang mit Menschen ist für Sie selbstverständlich. Sie arbeiten selbstständig und organisiert.
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Wir bieten Ihnen ein sich am TVstud der Berliner Universitäten orientierendes Gehalt. Die Arbeitszeit ist nach Absprache flexibel einteilbar, gerne auch am Nachmittag/Abend. Auch die wöchentlich Stundenanzahl ist frei nach Ihren Wünschen vereinbar.
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Weitere Informationen zu den Notaren Holland & Kreußlein finden Sie unter <a href="https://www.holland-kreusslein.de/">www.holland-kreusslein.</a>.
          </Typography>
          <Typography variant='body1' className='sectionText'>
          Haben Sie Interesse? Schreiben Sie gerne eine E-Mail an <a href="mailto:holland@holland-kreusslein.de">holland@holland-kreusslein.de</a>.
          </Typography>

        </AccordionDetails>
      </Accordion>

{/* 
        <Typography variant='body1' className='sectionText'>
        Ihre Bewerbung richten Sie bitte per E-Mail an <a href="mailto:info@holland-kreusslein.de">info@holland-kreusslein.de</a> oder auf dem Postweg an das Notarbüro Prenzlauer Straße 11 in 16515 Oranienburg.
          </Typography> */}
        
        </Container>

      </main >
    </div >
  );
}

export default Imprint;